import { animateScroll, scroller, Link } from 'react-scroll';
function Header(){
    const scrollTo = (id) => {
        scroller.scrollTo(id, {
            duration: 500,
            delay: 100,
        });
    };
    return (
        <header>
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="container">
                    <a href="/" className="navbar-brand">
                        <img src="/images/BAN-logo.png" alt="" className="d-inline-block align-text-top logo" />
                    </a>
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="/">Naslovna</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about-us" onClick={() => scrollTo('about-us')}>
                                O Nama
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#services" onClick={() => scrollTo('services')}>
                                Usluge
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact-us" onClick={() => scrollTo('contact-us')}>
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        
    );
}

export default Header;