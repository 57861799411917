function Footer(){
    return (
        <footer className="py-3 my-4">
            <div className="footer-widget-wrapper">
                <div className="row">
                    <div className="col-md-4 col-12 text-center">
                        <h5 className="footer-paragraph-title">Nalazimo se na adresi: </h5>
                        <p>
                        <i className="bi bi-geo-alt-fill"> </i>
                            <a className="find-us" href="https://goo.gl/maps/mPsKbz1fG5GQHtLs6" target="_blank">
                                <span>
                                Bosanskih kraljeva b.b. 
                                <br />
                                75320 Gračanica, Bosna i Hercegovina
                                </span>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-4 col-12 text-center">
                        <h5 className="footer-paragraph-title">Kontaktirajte nas: </h5>
                        <p> <i className="bi bi-telephone-fill"></i> Nazovite nas: <a className="call-us-phone" href="tel:+38762612704">+38762612704</a></p>
                        <p> <i className="bi bi-envelope-at-fill"></i> Pišite nam: <a className="call-us-phone" href="mailto:nihadtuholjak@gmail.com">nihadtuholjak@gmail.com</a></p>
                    </div>
                    <div className="col-md-4 col-12 text-center">
                        <h5 className="footer-paragraph-title">Radno vrijeme: </h5>
                        <div className="working-hours-container">
                            <div className="working-hours">
                                <p>Ponedjeljak:</p>
                                <p className="ml-auto">14:00 - 18:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Utorak:</p>
                                <p className="ml-auto">8:00 - 10:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Srijeda:</p>
                                <p className="ml-auto">8:00 - 10:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Četvrtak:</p>
                                <p className="ml-auto">8:00 - 10:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Petak:</p>
                                <p className="ml-auto">8:00 - 10:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Subota:</p>
                                <p className="ml-auto">8:00 - 16:00</p>
                            </div>
                            <div className="working-hours">
                                <p>Nedjelja i praznici:</p>
                                <p className="ml-auto">Neradni</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p className="text-center">© 2022-{new Date().getFullYear()} BAN Projekt - Sva prava zadržana</p>
            </div>
            
      </footer>
    )
}

export default Footer;